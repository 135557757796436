<template>
  <div>
    <b-card no-body class="card-custom" header-tag="header" footer-tag="footer">
      <template v-slot:header>
        <div class="card-title">
          <h3 class="card-label">
            {{ $t("MENU.ITEM.ASSETS.RENTED_MACHINES") }}
            <i class="mr-2"></i>
            <small class="">{{ $t("LABELS.LIST") }}</small>
          </h3>
        </div>
        <div class="card-toolbar">
          <router-link
            v-permission="['rented-machines-create']"
            to="add"
            class="btn btn-light-success font-weight-bolder"
            ><i class="flaticon2-plus"></i>
            {{ $t("LABELS.ADD_NEW") }}</router-link
          >
        </div>
      </template>
      <v-card v-show="!isPageLoading">
        <SearchTools
          :searchQuery.sync="searchQuery"
          @do-search="doSearch"
          @do-clear="doClear"
        >
        </SearchTools>
        <v-data-table
          :headers="headers"
          :items="items"
          :options.sync="options"
          :items-per-page="totalItems"
          :server-items-length="totalItems"
          :loading="isLoadingRentedMachine"
          loading-text="Loading... Please wait"
          :footer-props="{
            showFirstLastPage: true,
            itemsPerPageOptions: rowsPerPageItems,
            firstIcon: 'mdi-arrow-collapse-left',
            lastIcon: 'mdi-arrow-collapse-right',
          }"
          @update:options="getDataFromApi()"
          class="px-6 elevation-1"
        >
          <template v-slot:[`item.actions`]="{ item }">
            <router-link
              v-if="checkIfUserHasPermission(['rented-machines-update'])"
              :to="{ name: 'edit-rented-machine', params: { id: item.id } }"
            >
              <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn class="mr-2" fab dark x-small v-bind="attrs" v-on="on">
                    <v-icon dark>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t("LABELS.EDIT") }}</span>
              </v-tooltip>
            </router-link>
            <template v-else>Няма права</template>
          </template>
          <template v-slot:no-results>
            <v-alert :value="true" color="error" icon="warning">
              Your search for "{{ searchQuery }}" found no results.
            </v-alert>
          </template>
        </v-data-table>
      </v-card>

      <v-skeleton-loader
        v-if="isPageLoading"
        :loading="isPageLoading"
        type="table"
      ></v-skeleton-loader>
    </b-card>
  </div>
</template>

<script>
//General
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

import { mapGetters } from "vuex";

import SearchTools from "@/core/components/table/SearchTools.vue";

import { FETCH_RENTED_MACHINES } from "@/modules/rented-machines/store/rentedmachine.module";

import { permissionMixin } from "@/core/mixins/permissionMixin.js";

export default {
  mixins: [permissionMixin],
  data() {
    return {
      isPageLoading: true,
      tableLoading: true,
      items: [],
      totalItems: 100,
      searchQuery: "",
      options: {
        descending: true,
        page: 1,
        itemsPerPage: 30,
        totalItems: 100,
      },
      rowsPerPageItems: [10, 20, 30, 40, 50],
      columns: {},
      cancelSource: null,
      headers: [
        {
          text: this.$i18n.t("FORM_LABELS.REGISTRATION_PLATE"),
          value: "registrationPlate",
        },
        { text: this.$i18n.t("FORM_LABELS.TYPE"), value: "machineName" },
        {
          text: this.$i18n.t("FORM_PLACEHOLDERS.MACHINE_MODEL"),
          value: "machineModel",
        },
        {
          text: this.$i18n.t("FORM_LABELS.MACHINE_TYPE"),
          value: "machineType",
        },
        {
          text: this.$i18n.t("FORM_LABELS.SUBCONTRACTOR"),
          value: "subcontractorName",
          sortable: false,
        },
        {
          text: this.$i18n.t("FORM_LABELS.PHONE_SHORT"),
          value: "staffPhone"
        },
        {
          align: "right",
          text: this.$i18n.t("MENU.ITEM.ACTIONS"),
          value: "actions",
          sortable: false,
          width: "10%",
        },
      ],
    };
  },
  components: { SearchTools },
  watch: {},
  mounted() {
    let vm = this;
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: this.$i18n.t("MENU.ITEM.ASSETS.RENTED_MACHINES"),
        route: { name: "list-rented-machines" },
      },
    ]);
  },
  computed: {
    ...mapGetters(["isLoadingRentedMachine"]),
    params() {
      return {
        ...this.options,
        query: this.searchQuery,
      };
    },
  },
  methods: {
    cancelRequest() {
      //Axios cancelSource to stop current search if new value is entered
      if (this.cancelSource) {
        this.cancelSource.cancel("Start new search, stop active search");
      }
    },
    doSearch() {
      let vm = this;
      if (vm.options.page == 1) {
        vm.getDataFromApi();
      } else {
        vm.options.page = 1;
      }
    },
    doClear() {
      let vm = this;
      vm.searchQuery = "";
      if (vm.options.page == 1) {
        vm.getDataFromApi();
      } else {
        vm.options.page = 1;
      }
    },
    getDataFromApi() {
      let vm = this;
      // vm.tableLoading = true;
      //copy current params to modify
      let params = this.params;

      params.length = params.itemsPerPage; //set how many records to fecth per page
      params.start =
        params.page == 1 ? 0 : params.itemsPerPage * (params.page - 1); //set offset
      // console.log(this.params);
      let apiParams = vm.transformUrlParams(params);
      // console.log(apiParams);
      vm.$store
        .dispatch(FETCH_RENTED_MACHINES, apiParams)
        .then((data) => {
          vm.$nextTick(function() {
            vm.items = data.data.items;
            vm.totalItems = data.data.totalItemsCount;

            if (vm.isPageLoading == true) {
              vm.isPageLoading = false;
            }
          });
        })
        .catch((response) => {
          if (response.status === 404) {
            vm.$notify({
              group: "notify",
              type: "error",
              text: "Nout Found",
            });
            this.$router.push({ name: "list-rented-machines" });
          }
        });
    },
    transformUrlParams(params) {
      let apiParams =
        "?" +
        Object.keys(params)
          .map((key) => key + "=" + params[key])
          .join("&");
      return apiParams;
    },
    getColor(active) {
      if (active == false || active == 0) return "red";
      else return "primary";
    },
    textIsActive(active) {
      if (active == false || active == 0) return "Not Active";
      else return "Active";
    },
    iconIsActive(active) {
      if (active == false || active == 0) return "mdi-close-circle-outline";
      else return "mdi-checkbox-marked-circle-outline";
    },
  },
};
</script>
